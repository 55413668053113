.btn {
  padding: .5rem;
  border-radius: .7rem;
  font-size: 1rem;
  font-weight: 700;
  width: 100%;
  border: none;
  color: white;
  cursor: pointer;
}

.green {
  background-color: var(--green-500);
}

.red {
  background-color: var(--red-500);
}

.blue {
  background-color: var(--blue-500);
}

.yellow {
  background-color: var(--yellow-500);
}

.green:hover {
  background-color: var(--green-600);
}

.red:hover {
  background-color: var(--red-600);
}

.blue:hover {
  background-color: var(--blue-700);
}

.yellow:hover {
  background-color: var(--yellow-600);
}