.page {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100dvw;
  height: 100dvh;
  background-color: #480ca8;
}

.formLogin {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 40rem;
  justify-content: center;
  width: 30%;
  height: 100%;
  padding: 2rem;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.4);
  gap: 5px
}

.areaLogin img {
  width: 420px;
}

.formLogin h1 {
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 1.5em;
}

.formLogin p {
  display: inline-block;
  font-size: 14px;
  color: #666;
  margin-bottom: 25px;
}

.formLogin input {
  padding: 15px;
  font-size: 14px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  margin-top: 5px;
  border-radius: 4px;
  transition: all linear 160ms;
  outline: none;
}


.formLogin input:focus {
  border: 1px solid #f72585;
}

.formLogin label {
  font-size: 14px;
  font-weight: 600;
}

.formLogin a {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 13px;
  color: #555;
  transition: all linear 160ms;
}

.formLogin a:hover {
  color: #f72585;
}

.image {
  width: 70%;
  height: 100%;
  background-image: url('https://cdn.pixabay.com/photo/2016/02/01/00/56/news-1172463_1280.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header {
  color: #555555;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
}

.footer {
  font-size: 1rem;
  padding: 1rem 0;
  justify-content: center;
  align-items: center;
  display: flex;
}


@media (max-width: 1100px) {
  .formLogin {
    width: 45%;
  }

  .image{
    width: 65%;
  }
}

@media (max-width: 700px) {
  .formLogin {
    width: 70%;
  }

  .image{
    width: 30%;
  }
}

@media (max-width: 460px) {
  .formLogin {
    width: 100vw;
    justify-content: space-around;
    height: 100vh;
  }

  .image{
    display: none;
  }
}