.coolinput {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: static;
}

.coolinput label.text {
  font-size: 1em;
  color: var(--gray-900)333;
  font-weight: 700;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 0 3px;
  background: var(--white);
  width: fit-content;
}

.coolinput input.input {
  padding: 11px 10px;
  font-size: .9rem;
  border: 1px var(--gray-300) solid;
  border-radius: 5px;
  background: var(--white);
}

.coolinput input.input:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  border: 1px var(--purple-700) solid;
}

.coolinput label.text:focus {
  color: var(--purple-700);
}

.error{
  color: var(--red-400);
  font-size: .8rem;
  padding: .3rem 0;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .coolinput input.input {
    padding: 10px 0px 10px 5px;
  }
  
}